import { defaultStyleModel } from '@/includes/logic/Style/constant'
import { Style } from '@/includes/types/Post.types'
import store from '@/store/store'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import { Slot } from '@/includes/types/Board.types'
import { Advert, FrontendAdvert } from '@/includes/logic/Ads/types'
import router from '@/router'

import { cloneDeep } from 'lodash'
import Vue from 'vue'

let currentAdIndex: number | null = null;

type AdsState = {
    advert: FrontendAdvert | null
}

export const adStyleStructure: Array<keyof Partial<Style>> = [
        'id',
        'postfix_message',
        'prefix_message',
        'watermark_data',
        'watermark_type',
        'watermark_position',
    ]

export const state = Vue.observable<AdsState>({
    advert: null
})

function prepareStyle(modelCopy: FrontendAdvert) {
    const defaultSettings = cloneDeep(defaultStyleModel)

    Object.keys(modelCopy.style!).forEach((key) => {
        const fieldName = key as keyof Partial<Style>

        if (!adStyleStructure.includes(fieldName)) {
            if (modelCopy.style) {
                modelCopy.style[fieldName] = defaultSettings[fieldName]
            }
        }
    })

    modelCopy!.style!.data = null
}

export function prepareModelToSave() {
    return new Promise<Advert>(resolve => {
        const modelCopy = cloneDeep(state.advert!)

        if (modelCopy.expire !== undefined && modelCopy.expire <= 172800) {
            modelCopy.replace_message = null
        }

        if (modelCopy.style !== null) {
            prepareStyle(modelCopy)
        }

        resolve(modelCopy as Advert)
    })
}

export function findAdByArrIndex(index) {
    return store.state.boardsState.activeBoard!.config.ads[index - 1]
}

export function removeSlot(slotIndex:number) {
    state.advert!.plan.splice(slotIndex, 1)
}

export function editSlot(payload: Slot & { index?: number }) {
        const payloadCopy = { ...payload }

        delete payloadCopy.index

        state.advert!.plan.splice(payload.index!, 1, payloadCopy)
}

export function setReplaceMessage() {
    Vue.set(state.advert!, 'replace_message', {
        attachments: [],
        text: '',
        buttons: [],
        remove_previous: false,
        pin: false,
        disable_link_preview: false,
        disable_notify: false,
    })
}

export function saveAd(actionType: BaseItemViewAction) {
    prepareModelToSave()
        .then(res => {
            store.commit('pi/EXEC', {
                'fn': () => {
                    if (actionType === BaseItemViewAction.Edit) {
                        if (currentAdIndex) {
                            store.state.boardsState.activeBoard!.config.ads.splice(currentAdIndex - 1, 1, res)
                        }
                    } else {
                        if (store.state.boardsState.activeBoard!.config.ads === null) {
                            store.state.boardsState.activeBoard!.config.ads = []
                        }

                        store.state.boardsState.activeBoard!.config.ads.push(res)
                    }
                },
            })
        })

        .then(() => {
            store.dispatch('savePostBoardConfig')
                .then(() => {
                    router.push({ name: 'OffersList' })
                })
        })
}

export const init = (index?:number) => {
    if (index) {
        currentAdIndex = index

        const ad = findAdByArrIndex(index)

        if (ad) {
            state.advert = ad
        } else {
            router.push({
                name: 'OffersList'
            })
        }
    } else {
        state.advert = {
            name: '',
            targets: [],
            rules: '',
            cash_register_id: null,
            product_id: null,
            plan: [],
            expire: 86400,
            top: 7200,
            pin: 0,
            moderation_timeout: 86400,
            pay_timeout: 3600,
            style: null
        }
    }
}