






















































































































































































































import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { InputSetups } from '@/mixins/input-setups'
import PostShopFieldsWrapper from '@/components/Post/PostShopFieldsWrapper.vue'
import SlotsList from '@/components/Slots/SlotsList.vue'
import SaveChannelConfig from '@/components/SaveChannelConfig.vue'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import PostStylesSetup from '@/components/Post/PostStylesSetup.vue'
import { Style } from '@/includes/types/Post.types'
import { state, editSlot, init, removeSlot, saveAd, setReplaceMessage, adStyleStructure } from '@/includes/logic/Ads/AdsLogic'
import { Slot } from '@/includes/types/Board.types'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import MessageEditorWithMediaInput
  from 'piramis-base-components/src/components/Pi/fields/NewMessageEditorWithMediaInput/MessageEditorWithMediaInput.vue'

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

@Component({
  components: {
    SaveChannelConfig,
    SlotsList,
    PostShopFieldsWrapper,
    PageTitle,
    ConfigField,
    PostStylesSetup,
    Accordion,
    MessageEditorWithMediaInput
  },
  data() {
    return {
      EntityTypes,
      state,
      adStyleStructure
    }
  }
})
export default class OffersListItem extends Mixins<UseFields, InputSetups, ModelSetter>(UseFields, InputSetups, ModelSetter) {

  @Watch('state.advert', { deep: true })
  onAdvertChange() {
    const advert = state.advert;

    if (advert) {
      if (advert.expire !== undefined && advert.expire > 172800 && (advert.replace_message === undefined || advert.replace_message === null)) {
        setReplaceMessage()
      }
    }
  }

  styleId: number | null =  null

  get styles() {
    const defaultOption = [ {
      label: this.$t('advert_style_none').toString(),
      value: null,
    } ];

    if (this.$store.getters.activeBoard.config.styles !== null) {
      const styleEntries = Object.entries(this.$store.getters.activeBoard.config.styles)

      return (styleEntries.length ? styleEntries : []).reduce<Array<SelectOption>>((acc, [ key, value ]) => {
        acc.push({ label: key, value: (value as Style).id })

        return acc
      }, defaultOption)
    } else {
      return defaultOption
    }
  }

  get selectedStyle() {
    return this.styles.find(s => s.value === this.styleId)
  }

  get adId() {
    return Number.parseInt(this.$route.params.adId)
  }

  get saveConfigButtonDisabled() {
    return !state.advert!.name.length
      || !state.advert!.rules.length
      || !state.advert!.targets.length
      || !state.advert!.plan.length
      || state.advert!.cash_register_id === null
      || state.advert!.product_id === null
  }

  onSelectedStyleChange(value:any) {
    if (value.value === null) {
      state.advert!.style = null
    } else {
      this.$set(state.advert!, 'style', cloneDeep(this.$store.getters.activeBoard.config.styles[value.label]))
    }
  }

  onEditSlot(payload: Slot & { index?: number }) {
    editSlot(payload)
  }

  onRemoveSlot(slotIndex:number) {
    removeSlot(slotIndex)
  }

  onSaveClick() {
    saveAd(this.$route.params.actionType as BaseItemViewAction)
  }

  created() {
    if (this.$route.params.actionType === BaseItemViewAction.Edit) {
      init(this.adId)
      this.styleId = state.advert!.style?.id ?? null
    } else {
      init()
    }
  }
}
